/**
 * @todo products navigation
 */
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import ProductAction from '../actions/ProductAction'
import BookPagesBanner from './Banners/BookPagesBanner'
import Image from './Images'
import { AgeBadge } from './Badges'
import Style from '../styles/components/products.module.scss'

const StoryContent = (props) => {
  const _t = (str) => props._t[props._t.findIndex(item => item.key_text === str)].text.text;
  const data = props.data;
  const shopify_item = data.shopify_item && data.shopify_item.variants[0];
  const product = {
    id: shopify_item && Buffer.from(shopify_item.admin_graphql_api_id.toString()).toString('base64'),
    projectId: data.project_id,
    name: data.title.text,
    price: data.price.text,
    type: data.type,
    isBook: JSON.parse(data.physical_book),
    isPrintable: JSON.parse(data.printable),
    inAppPurchase: JSON.parse(data.in_app_purchase),
    popupBackground: data.popup_background,
    pageTracking: 'boutique'
  };
  const shipping = data.shipping && data.shipping.document[0].data 
  const videoId = data.video.embed_url && data.video.embed_url.split('?v=');
  
  if (_t === undefined) {
    return <div></div>
  }
  
  return (
    <div className={`container product-page ${props.viewportSize}`}>
      <div className="breadcrumb">
        <Link to="/">Wakatoon</Link>&nbsp;/&nbsp;
        <Link to={props.pageContext.breadcrumbParent.slug}>
          {props.pageContext.breadcrumbParent.label}
        </Link>
        &nbsp;/ {data.title.text}
      </div>

      <div className="row">
        <div className={`${Style.col6} col-6`}>
          <div className={`${Style.productImage} product-image wrappers`}>
            { data.age.text && <AgeBadge {...data.age} /> }
            <Image src={data.image.url} alt={data.cover_alt_attribute} />
          </div>
          <BookPagesBanner twic={props.twic} {...data} />
        </div>

        <div className={`${Style.col6} col-6`}>
          <div className={`${Style.productTitle} wrappers`}>
            <h2>{data.title.text}</h2>
            <h3>
              {data.author.text}
              {data.illustrations.text && ` - ${data.illustrations.text}`}
            </h3>
          </div>
          <div className="product-description-wrapper">
            <h4>{_t(`L'histoire`)} :</h4>
            <p className="product-description">{data.description.text}</p>
          </div>

          <div className={`${Style.productOrder}`}>
            {
              props.pageContext.langKey !== 'en' ? (
                <div className={`${Style.productPrice} product-price`}>
                  <span>
                    {
                      data.shopify_item && 
                      <>
                        {
                          product.price 
                          ? product.price
                          : data.shopify_item.variants[0].price.replace('.', ',')
                        }
                      </>
                    } €
                  </span>
                </div>
              ) : (
                <div className={`${Style.productPrice} product-price`}>
                  {
                    data.try_it_out && (
                      <p style={{fontSize:13, paddingTop: 0, paddingBottom: 10}} className="product-description">{data.try_it_out.text}</p>
                    )
                  }
                  <span>
                    ${product.price}
                  </span>
                </div>
              )
            }
            <div className="button-wrapper">
              {Object.keys(product).length > 0 && (
                <ProductAction 
                  page="story" 
                  product={product} 
                  context={props.pageContext} 
                  _t={props._t}
                />
              )}
            </div>
          </div>
          {
            data.la_wakanecdote.text &&
            <div className={Style.productNote}>
              <p className={Style.h5}>{_t('La Wakanecdote')} :</p>
              <p>{data.la_wakanecdote.text}</p>
            </div>
          }
        </div>
      </div>

      <div className="product-infos">
        {
          data.book_content.text &&
          <div className="row">
            <div className="col-12">
              <p className={Style.h4}>{_t('Le contenu')} :</p>
              <div dangerouslySetInnerHTML={{ __html: data.book_content.html }}></div>
            </div>
          </div>
        }

        {
          shipping &&
          <>
            <div className="row">
              <div className="col-12">
                <p className={Style.h4}>{_t('Livraison')} :</p>
              </div>
            </div>

            <div className="row">
              <div className="col-12" dangerouslySetInnerHTML={{
                __html: shipping.shipping__france_.html.replace('h6','p')
              }}></div>
            </div>
          </>
        }
      </div>

      {
        videoId &&
        <div className="row">
          <div className="col-12">
            <div className="yt-container">
              <iframe
                className="yt-video"
                title={`Vidéo: ${data.title.text}`}
                width="480"
                height="270"
                src={`https://www.youtube.com/embed/${videoId[1]}?feature=oembed`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen>
              </iframe>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

const mapStateToProps = (state) => ({ viewportSize: state.viewport.viewportSize });
export default connect(mapStateToProps)(StoryContent);