import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/Index'
import StoryContent from '../components/StoryContent'
import SEO from '../components/SEO'
import Books from '../components/Banners/Books';
import Style from '../styles/components/products.module.scss'

const Story = ({ data: { prismicStory, books, staticText }, pageContext }) => {
  const { data } = prismicStory;
  const staticPrismicTexts = staticText.edges[0].node.data.texts;
  const _t = (str) => staticPrismicTexts[staticPrismicTexts.findIndex(item => item.key_text === str)].text.text;
  
  return (
    <Layout 
      context={pageContext} 
      pageTitle={prismicStory.data.seo__meta_title_.text}
      _t={staticPrismicTexts}
    >
      <SEO 
        pageTitle={prismicStory.data.seo__meta_title_.text} 
        pageDescription={prismicStory.data.seo__meta_description_.text} 
        context={pageContext}
      />
      <div className={`${Style.productPage} page`}>
        <section className="regular">
          <StoryContent 
            data={data} 
            shopUrl={pageContext.shopUrl} 
            twic={pageContext.twic}
            pageContext={pageContext} 
            _t={staticPrismicTexts}
          />
        </section>
      </div>
      <div style={{paddingTop: 30}}>
        <Books 
          {...books} 
          title={_t(`Nos autres livres`)} 
          shopUrl={pageContext.shop_urls[pageContext.langKey]} 
          context={pageContext}
          _t={staticPrismicTexts}
        />
      </div>
    </Layout>
  );
};

export default Story;

export const pageQuery = graphql`
  query StoryBySlug($uid: String!, $locale: String!) {
    prismicStory(uid: { eq: $uid }, lang: {eq: $locale}) {
      lang
      uid
      data {
        seo__meta_title_ {
          text
        }
        seo__meta_description_ {
          text
        }
        project_id
        title {
          text
        }
        price {
          text
        }
        age {
          text
        }
        author {
          text
        }
        illustrations {
          text
        }
        description {
          text
        }
        try_it_out {
          text
        }
        la_wakanecdote {
          text
        }
        book_content {
          text
          html
        }
        image {
          url
        }
        video {
          embed_url
        }
        pages {
          page {
            url
          }
          image_alt_attribute
        }
        cover_alt_attribute
        type
        physical_book
        printable
        in_app_purchase
        shopify_item {
          id
          admin_graphql_api_id
          variants {
            id
            admin_graphql_api_id
            product_id
            price
          }
        }
        shipping {
          document {
            data {
              shipping__france_ {
                html
              }
              shipping__world_ {
                html
              }
            }
          }
        }
        popup_background {
          url
        }
      }
    }
    books: allPrismicBlockStoryslider(filter: { 
      lang: { eq: $locale } 
      data: {
        stories: {
          elemMatch: {
            story: {
              uid: {ne: $uid}
            }
          }
        }
      }
    }) {
      edges {
        node {
          lang
          data {
            title {
              text
            }
            stories {
              story {
                document {
                  uid
                  data {
                    project_id
                    age {
                      text
                    }
                    title {
                      text
                    }
                    short_title {
                      text
                    }
                    title_part_1 {
                      text
                    }
                    title_part_2 {
                      text
                    }
                    description {
                      text
                    }
                    image {
                      url
                    }
                    type
                    physical_book
                    printable
                    in_app_purchase
                    home_featured
                    shopify_item {
                      id
                      admin_graphql_api_id
                      variants {
                        id
                        admin_graphql_api_id
                        product_id
                        price
                      }
                    }
                    popup_background {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
      edges { ...StaticTexts }
    }
  }
`;