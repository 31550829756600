import React from 'react'
import { connect } from 'react-redux'
import { handleModalBanner } from '../../actions/modal'
import Image from '../Images'

import Style from '../../styles/components/products.module.scss'

const BookPagesBanner = (props) => {
  return (
    <div>
      {( props.pages.length > 0 && props.pages[0].page.url) &&
      <div className={Style.productPages}>
        { props.pages.map((item, index) => {
          return index < 3 &&
            <div 
              key={`product-page-${index}`} 
              className={Style.pagesWrapper}
              onClick={ () => props.handleModalBanner({
                modalBanner: !props.modalBanner,
                images: props.pages,
                currentImage: item,
                initialSlide: index
              })}
            >
              <Image src={item.page.url} alt={item.image_alt_attribute} />
            </div>
        })}
      </div> }
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state.modalBanner
});
const mapDispatchToProps = (dispatch) => ({
  handleModalBanner: (state) => dispatch(handleModalBanner(state))
});
export default connect(mapStateToProps, mapDispatchToProps)(BookPagesBanner);